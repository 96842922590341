var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["ModuleTitleBar", _vm.isReverse ? "reverse" : ""] },
    [
      _c("span", { staticClass: "left" }, [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("span", { staticClass: "left-line" }),
      ]),
      _c(
        "div",
        { staticClass: "middle" },
        _vm._l([1, 2, 3, 4, 5, 6], function (index) {
          return _c("span", {
            key: index,
            staticClass: "bar",
            style: { opacity: 1 - index * 0.15 },
          })
        }),
        0
      ),
      _c("span", { staticClass: "right" }, [
        _c("span", { staticClass: "right-bar" }),
        _c("span", { staticClass: "right-line" }),
        _c("img", {
          staticClass: "icon5",
          attrs: {
            src: _vm.isReverse
              ? require("../../assets/images/moduleTitleBar/icon6.png")
              : require("../../assets/images/moduleTitleBar/icon5.png"),
            alt: "",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }