import { Loading, Message } from 'element-ui';
var errorMap = {};
export default {
  loadingInstance: null,
  loading: function loading() {
    this.loadingInstance = Loading.service({
      fullscreen: true,
      background: 'rgba(255, 255, 255, 0.8)',
      text: 'loading...'
    });
  },
  warn: function warn(msg) {
    this.hide();

    if (!errorMap[msg]) {
      errorMap[msg] = 1;
      Message({
        message: msg,
        showClose: true,
        duration: 2000,
        type: 'warning',
        onClose: function onClose() {
          delete errorMap[msg];
        }
      });
    }
  },
  success: function success(msg) {
    this.hide();
    Message({
      message: msg,
      showClose: true,
      duration: 2000,
      type: 'success'
    });
  },
  hide: function hide() {
    if (this.loadingInstance !== null) {
      this.loadingInstance.close();
    }
  }
};