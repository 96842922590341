import _toConsumableArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import router from '../router/index';
export default {
  // 生成随机数 min ≤ r ≤ max
  random: function random(min, max) {
    return Math.round(Math.random() * (max - min)) + min;
  },
  // 格式化金额
  formatAmount: function formatAmount(amount) {
    if (!amount) {
      return 0.0;
    }

    return '¥' + (amount / 10000).toFixed(2);
  },
  // 判断字符串是不是日期格式
  isDateString: function isDateString(dateStr) {
    return isNaN(dateStr) && !isNaN(Date.parse(dateStr));
  },
  // 百分比向上取整   小于5%，最高用5%   大于5%，向上取整到十位， 即10%、20%。。。
  percentsUpCeil: function percentsUpCeil() {
    var numbers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var max = Math.max.apply(Math, _toConsumableArray(numbers));
    var bite = 0;

    if (max < 5) {
      return 5;
    }

    while (max >= 10) {
      max /= 10;
      bite += 1;
    }

    var result = Math.ceil(max) * Math.pow(10, bite);
    return result;
  },

  /**
   * 判断是否为空
   * @param {*} val
   */
  isNull: function isNull(val) {
    if (typeof val === 'number' && !isNaN(val) && val === 0) {
      return false;
    }

    return val == null || val === undefined || val === '' || val === 'null' || val === 'undefined' || val.length === 0;
  },

  /**
   * 判断是否不为空
   * @param {*} val
   */
  isNotNull: function isNotNull(val) {
    return !this.isNull(val);
  },

  /**
   * 构建成功对象
   */
  buildSuccess: function buildSuccess(data) {
    return {
      code: '1',
      msg: '成功',
      data: data,
      error: false,
      success: true
    };
  },

  /**
   * 构建失败对象
   */
  buildError: function buildError() {
    var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '500';
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '网络异常，请联系管理员！';
    return {
      code: code,
      msg: message,
      error: true,
      success: false
    };
  },
  getCurrentRouterName: function getCurrentRouterName() {
    return router.currentRoute.name;
  }
};
/**
 * @description 绑定事件 on(element, event, handler)
 */

export var on = function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
}();
/**
 * @description 解绑事件 off(element, event, handler)
 */

export var off = function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
}();