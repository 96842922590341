import _objectSpread from "/home/jenkins/agent/workspace/digitalnmn8f/digital-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getTenantSettings } from '@/api/user';
import cache from '@/utils/cache';
import { mapActions } from 'vuex';
import Tool from '@/utils/tool';
export default {
  name: 'Login',
  data: function data() {
    return {
      loading: true,
      loginText: '',
      loginLoading: false,
      tenantCode: '',
      platformName: '',
      bgImgUrl: '',
      footer: '',
      // liantong liantong@2021
      form: {
        loginName: '',
        password: ''
      },
      tenantSettings: {
        platformName: null,
        loginPlatformColor: null,
        loginPlatformBg: null,
        loginPlatformInfo: null
      }
    };
  },
  computed: {
    rgbColor: function rgbColor() {
      var _this = this;

      return function (p) {
        // 16进制颜色值的正则
        var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        var defaultColor = 'rgba(0,25,66,' + p + ')';

        if (Tool.isNull(_this.tenantSettings.loginPlatformColor)) {
          return defaultColor;
        } // 把颜色值变成小写


        var color = _this.tenantSettings.loginPlatformColor.toLowerCase();

        if (reg.test(color)) {
          // 如果只有三位的值，需变成六位，如：#fff => #ffffff
          if (color.length === 4) {
            var colorNew = '#';

            for (var i = 1; i < 4; i += 1) {
              colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
            }

            color = colorNew;
          } // 处理六位的颜色值，转为RGB


          var colorChange = [];

          for (var _i = 1; _i < 7; _i += 2) {
            colorChange.push(parseInt('0x' + color.slice(_i, _i + 2)));
          }

          colorChange.push(p);
          return 'rgba(' + colorChange.join(',') + ')';
        } else {
          return defaultColor;
        }
      };
    },
    style: function style() {
      return {
        width: '100%',
        height: '100vh',
        '--BgColor': this.rgbColor(0.7),
        '--InputBgColor': this.rgbColor(0.3),
        '--InputBorderColor': this.rgbColor(1),
        '--InputHoverColor': this.rgbColor(0.7),
        '--BtnColor': this.rgbColor(0.3),
        '--BtnBorderColor': this.rgbColor(1),
        '--BtnHoverColor': this.rgbColor(0.7),
        '--BtnHoverBorderColor': this.rgbColor(0.7)
      };
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['handleLogin'])), {}, {
    init: function init() {
      var _this2 = this;

      this.tenantCode = this.$route.params.tenantCode;
      cache.setTenantCode(this.tenantCode);
      var params = {
        tenantCode: this.tenantCode
      };
      getTenantSettings(params).then(function (result) {
        _this2.tenantSettings = result;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    perLogin: function perLogin() {
      var _this3 = this;

      if (Tool.isNotNull(this.form.loginName) && Tool.isNotNull(this.form.password)) {
        this.onLogin();
      } else if (Tool.isNull(this.form.loginName)) {
        this.$nextTick(function () {
          _this3.$refs["loginName"].focus();
        });
      } else if (Tool.isNull(this.form.password)) {
        this.$nextTick(function () {
          _this3.$refs["password"].focus();
        });
      }
    },
    onLogin: function onLogin() {
      var _this4 = this;

      if (!this.form.loginName) {
        this.toast.warn('请输入您的登录账号');
        return;
      }

      if (!this.form.password) {
        this.toast.warn('请输入您的登录密码');
        return;
      }

      this.loginLoading = true;
      this.loginText = '正在登录...';
      this.handleLogin(this.form).then(function () {
        _this4.loginText = '登录成功，正在跳转...';
        setTimeout(function () {
          _this4.$router.push('/');
        }, 500);
      }).catch(function () {
        _this4.loginLoading = false;
      });
    },
    formatColor: function formatColor(value) {
      var nameToHex = {
        red: '#ff0000',
        green: '#00ff00',
        blue: '#0000ff'
      }[value.toLowerCase()];
      value = nameToHex || value; // HEX#ERGB

      console.log(parseInt('ex' + value.slice(1, 3)));
      return [parseInt('ex' + value.slice(1, 3)), parseInt('ex' + value.slice(3, 5)), parseInt('ex' + value.slice(5, 7))];
    }
  }),
  created: function created() {
    this.init();
  },
  mounted: function mounted() {}
};