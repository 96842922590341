import Login from '@/views/users/login';
import NotFound from '@/views/others/404.vue';
import cache from '@/utils/cache';
export default [{
  path: '/login',
  name: 'redirectLogin',
  redirect: function redirect() {
    var tenantCode = cache.getTenantCode();
    return {
      path: "/".concat(tenantCode, "/login")
    };
  }
}, {
  path: '/:tenantCode/login',
  name: 'login',
  meta: {
    title: '登录',
    hideInMenu: true
  },
  component: Login
}, // {
//   path: '/', // 切换成history的话，需要这个配置
//   // FIXME:上线前修正
//   redirect: '/app/home'
// },
// {
//   path: '/app',
//   name: 'app',
//   redirect: '/home',
//   component: Layout,
//   children: []
// },
{
  path: '/404',
  component: NotFound
} // {
//   path: '*', redirect: '/404'
// }
]; //
// const createRouter = () => new Router({
//   mode: 'history', // require service support
//   routes: constantRoutes
// });
//
// // router.afterEach(to => {
// //   window.scrollTo(0, 0);
// // });
//
// const router = createRouter();
//
// export default router;